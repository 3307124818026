// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/statCard/statCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/statCard/statCard.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import React from "react";
import Typography from "../typography/typography";
import { formatNumToLocal } from "~/lib/formatNumber";
import { motion } from "framer-motion";
import { fadeIn } from "~/lib/variant";
const StatCard = ({
  value,
  title,
  index
}) => {
  return <motion.div variants={fadeIn("down", 0.8 + index * 0.2, 0.8)} initial="hidden" whileInView="show" exit="hidden" viewport={{
    once: true
  }} className="rounded p-4 flex flex-col items-center gap-y-1 md:items-start bg-card
    last:col-span-2 md:last:col-span-1 shadow-sm shadow-card">
      <Typography variant="title1">{value > 100 && "+"}{formatNumToLocal(value)}</Typography>
      <Typography variant="body1" className="text-gray-400">
        {title}
      </Typography>
    </motion.div>;
};
_c = StatCard;
export default StatCard;
var _c;
$RefreshReg$(_c, "StatCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;