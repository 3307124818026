// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/formatNumber.ts"
);
import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

export const formatNumToLocal = (num: number) => {
  if (num >= 1000) {
    const kValue = Math.floor(num / 1000);
    return `${kValue} هزار`;
  } else {
    return num.toString();
  }
}
